<template>
  <div>
    <v-row class="mt-10">
      <v-col class="text-center" offset-md="4" md="4">
        <h2>Integração Google Agenda</h2>
        <v-img
          class="mt-10 mx-auto"
          width="200px"
          :src="'https://cdn.icon-icons.com/icons2/2642/PNG/512/google_calendar_logo_icon_159345.png'"
        />
        <div class="my-4">Conecte o seu Psicoplanner ao seu Google Agenda!</div>
        <v-btn
          color="primary"
          @click="handleConnect()"
          v-if="!isAuthrizedGoogleCalendar"
          >Conectar</v-btn
        >
        <v-btn
          color="secondary"
          v-if="isAuthrizedGoogleCalendar"
          @click="handleDisconect()"
          >Desconectar</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    isAuthrizedGoogleCalendar() {
      return this.user.google_calendar_status;
      return false;
    },
  },

  mounted() {
    this.$auth.getLoginData();
    if (!this.isAuthrizedGoogleCalendar && this.$route.query.code) {
      this.postAuth(this.$route.query.code);
    }
  },

  methods: {
    async handleConnect() {
      await this.$http
        .$get("integrations/auth-google-calendar/url")
        .then(async (response) => {
          window.location.href = response;
        });
    },

    async postAuth(code) {
      await this.$http
        .$post("integrations/auth-google-calendar/auth", { code: code })
        .then(async (response) => {
          //await this.$auth.getLoggedUser();
          this.$auth.getLoginData();
        });
    },

    async handleDisconect() {
      await this.$http
        .$post("integrations/auth-google-calendar/disconnect")
        .then(async (response) => {
          this.$auth.getLoginData();
        });
    },
  },
};
</script>

<style>
</style>